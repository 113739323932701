import { Component, Input, OnInit } from '@angular/core';
import {ISubscription} from '../../../../types/subscription';
import { IIncludedSoftware } from '../products.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Observable} from 'rxjs';
import {IManageUser, UserRole} from '../../../../types/user';
import {selectUser} from '../../../store/user/user.selectors';

@Component({
  selector: 'app-data-defense-sub',
  templateUrl: './data-defense-sub.component.html',
  styleUrls: ['./data-defense-sub.component.scss']
})
export class DataDefenseSubComponent implements OnInit {
  user$: Observable<IManageUser> = this.store.select(selectUser())

  @Input() subscription: ISubscription;
  data: IIncludedSoftware[] = []
  columnsToDisplay = ['productName', 'assignedLicenses', 'purchasedQuantity'];

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit() {
    this.data = [
      {
        productName: "Cigent CLI",
        assignedLicenses: 0,
        licensesApply: false,
        canAssign: false,
        hasEndpoints: false,
        purchasedQuantity: this.subscription.count,
      },
      {
        productName: "PBA Licenses",
        assignedLicenses: this.subscription.pba_used,
        licensesApply: true,
        canAssign: true,
        hasEndpoints: false,
        purchasedQuantity: this.subscription.count,
      },
      {
        productName: "Data Defense",
        assignedLicenses: this.subscription.used,
        licensesApply: true,
        canAssign: false,
        hasEndpoints: true,
        purchasedQuantity: this.subscription.count,
      },

    ];
  }

  protected readonly UserRole = UserRole;
}
