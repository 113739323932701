import { Component, Input, OnInit } from '@angular/core';
import {ISubscription} from '../../../../types/subscription';
import { IIncludedSoftware } from '../products.component';

@Component({
  selector: 'app-bitlocker-sub',
  templateUrl: './bitlocker-sub.component.html',
  styleUrls: ['./bitlocker-sub.component.scss']
})
export class BitlockerSubComponent implements OnInit{
  @Input() subscription: ISubscription;
  data: IIncludedSoftware[] = []
  columnsToDisplay = ['productName', 'assignedLicenses', 'purchasedQuantity'];

  ngOnInit() {
    this.data = [
      {
        productName: "Data Defense",
        assignedLicenses: this.subscription.used,
        licensesApply: true,
        purchasedQuantity: this.subscription.count,
        hasEndpoints: false,
        canAssign: false
      },
    ];
  }

}
