import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { D3eService } from '../../../../services/d3e-service';
import { NotificationService } from '../../../../services/notification-service';
import { TranslateService } from '@ngx-translate/core';

export interface IAddPbaSerialData {
  subscriptionId: string,
  selectedOrgId: string,
}

@Component({
  selector: 'app-add-ssd-serial-dialog',
  templateUrl: './add-ssd-serial-dialog.component.html',
  styleUrls: ['./add-ssd-serial-dialog.component.scss']
})
export class AddSsdSerialDialogComponent {
  form: UntypedFormGroup;
  @Output() serialSubmitted = new EventEmitter<string>();

  adding = false;

  error_occurred: string = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IAddPbaSerialData,
              private readonly formBuilder: UntypedFormBuilder,
              private readonly dialogRef: MatDialogRef<AddSsdSerialDialogComponent>,
              private readonly d3eService: D3eService,
              private notification: NotificationService,
              private translate: TranslateService) {
    this.form = this.formBuilder.group({
      serial: ['', Validators.required]
    });
  }


  addSerial(newSerialText: string, notify=false) {
    this.adding = true;
    this.error_occurred = null;
    this.d3eService.addPbaSerial(this.data.selectedOrgId, this.data.subscriptionId, newSerialText).subscribe(response => {
          response.new_serials.forEach( serial => {this.serialSubmitted.emit(serial)});
          this.adding = false;
          this.form.reset();
          if (notify) {
             this.notification.showSuccess(this.translate.instant('assign-licenses-add-ssd-serial-dialog.serial-added'));
             this.dialogRef.close();
          }
        },
        error => {
          this.adding = false;
          if ( error && error['reason'] ) {
            if ( error['reason'] == 'serial_already_exists' ) {
              this.error_occurred = 'assign-licenses-add-ssd-serial-dialog.already-exists'
            }
          }
        });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.addSerial(this.form.value.serial, true);
  }

  onEnterPressed(event) {
    if (this.form.value.serial != null &&
        this.form.value.serial.length &&
        this.form.value.serial.indexOf(',') == -1) {
        event.stopPropagation()
        event.preventDefault()
        this.addSerial(this.form.value.serial, false);
    }
  }

    onSaveAndAddAnotherClick(event) {
    if (this.form.value.serial != null &&
        this.form.value.serial.length ) {
        this.addSerial(this.form.value.serial, false);
    }
  }

}
