import {IManageUser} from '../../../types/user';
import {on, createReducer} from '@ngrx/store';
import {userLoadedSuccess} from './user.actions';

export const initialState: IManageUser = null;

export const userReducer = createReducer(
  initialState, on(
    userLoadedSuccess,
    (state, {user}) => user
  )
);
