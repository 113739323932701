<!--<h2 mat-dialog-title>{{'assign.details.title' | translate}}</h2>-->

<div>
    <mat-card appearance="raised">
      <mat-card-header>
        <mat-card-title><span class="mdi mdi-information-outline title-icon"></span>{{'assign.details.title' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="details-wrapper">
            <div class="details-data">
                <div>
                    <ul>
                        <li style="display: flex;">
                            <span class="title" id="serials">{{'assign.details.serials' | translate}}</span><span style="display: inline-block">{{serials}}</span>
                        </li>
                        <li >
                            <span class="title" id="max_ssd">{{'assign.details.max_ssd' | translate}}</span><span>{{maxSSD}}</span>
                        </li>
                        <li>
                            <span class="title" id="extended">{{'assign.details.extended' | translate}}</span><span>{{extendedFeatures | translate}}</span>
                        </li>
                        <li>
                            <span class="title" id="cmd_log">{{'assign.details.cmd_log' | translate}}</span><span>{{cmdLog | translate}}</span>
                        </li>
                        <li>
                            <span class="title" id="raid">{{'assign.details.raid' | translate}}</span><span>{{raid | translate}}</span>
                        </li>
                        <li>
                            <span class="title" id="licenses">{{'assign.details.licenses_consumed' | translate}}</span><span>{{licensesConsumed}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div mat-dialog-actions>
        <button mat-raised-button type="button" color="primary" (click)="onCloseClick()">{{'CLOSE' | translate}}</button>
    </div>
</div>
