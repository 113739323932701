import { Component, Inject, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface LicenseDetailsData {
    'serials': string[],
    'extendedFeaturesEnabled': boolean,
    'eraseCmdLogEnabled': boolean,
    'raidEnabled': boolean,
    'licensesConsumed': number,
    'maxSsd': number
}


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent {
  MAX_SERIALS_LEN = 64;

  serials: string;
  showMoreSerials = false;
  maxSSD: number;
  extendedFeatures = 'DISABLED';
  cmdLog = 'DISABLED'
  raid = 'DISABLED'
  licensesConsumed: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: LicenseDetailsData,
              private readonly dialogRef: MatDialogRef<LicenseDetailsData>
              ) {
    this.serials = data.serials.sort().join(', ');
    this.maxSSD = data.maxSsd;
    this.extendedFeatures = data.extendedFeaturesEnabled ? 'ENABLED' : 'DISABLED';
    this.cmdLog = data.eraseCmdLogEnabled ? 'ENABLED' : 'DISABLED';
    this.raid = data.raidEnabled ? 'ENABLED' : 'DISABLED';
    this.licensesConsumed = data.licensesConsumed;
  }

  onInit() {
  }

  onCloseClick() {
        this.dialogRef.close();
  }
}
