<h2 mat-dialog-title>{{'assign-licenses-add-ssd-serial-dialog.title' | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>{{'assign-licenses-add-ssd-serial-dialog.serial-label' | translate}}</mat-label>
            <textarea matInput type="text" formControlName="serial" (keydown.enter)="onEnterPressed($event)" id="pba-serials"></textarea>
        </mat-form-field>
    </form>
    <div class="mat-error" *ngIf="error_occurred">
        <span class="mat-error">{{error_occurred | translate}}</span>
    </div>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-raised-button type="button" id="close-button" color="primary" (click)="onCancelClick()">{{'H_CLOSE' | translate}}</button>
    <button mat-raised-button type="button" id="save-add-button" color="primary" (click)="onSaveAndAddAnotherClick($event)" [disabled]="adding || !form.valid">{{'SAVE_AND_ADD_ANOTHER' | translate}}</button>
    <button mat-raised-button type="button" id="save-button" color="primary" (click)="onSaveClick()" [disabled]="adding || !form.valid">{{'SAVE' | translate}}</button>
</div>
